<template>
  <div>
    <b-modal
      :id="$route.name + 'CreateModal'"
      title="Добавить тариф"
      size="lg"
      hide-footer
      no-enforce-focus
      :no-close-on-backdrop="true"
      @show="clearData"
    >
      <b-row>
        <b-col cols="6">
          <b-row>
            <b-col>
              <label for="">Подразделение <i style="color: red">*</i></label>
              <b-form-group>
                <v-select
                  label="name"
                  v-model="tariff.division_id"
                  :options="divisions"
                  class="select-size-sm"
                  :reduce="(item) => item.id"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <label for="">Тип тарифа <i style="color: red">*</i></label>
                <v-select
                  label="name"
                  v-model="tariffType"
                  :options="tariffTypes"
                  class="select-size-sm"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <label for="">Тип платежа <i style="color: red">*</i></label>
                <v-select
                  label="name"
                  v-model="tariff.pay_types"
                  :options="data_pay_types"
                  :reduce="(item) => item.id"
                  multiple
                  class="select-size-sm"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label for="">Наименование <i style="color: red">*</i></label>
              <b-form-group>
                <b-input size="sm" v-model="tariff.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label for="">Цена подачи <i style="color: red">*</i></label>
              <b-form-group>
                <b-input
                  size="sm"
                  v-model="tariff.delivery_price"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <label for="">Минимальная цена <i style="color: red">*</i></label>
              <b-form-group>
                <b-input size="sm" v-model="tariff.min_price" type="number" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row cols="3">
            <b-col>
              <label style="user-select: none">Цена за 1км(город):</label>
              <b-form-group>
                <b-input
                  size="sm"
                  v-model="tariff.price_km_city"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <label>Цена за 1км(межгород):</label>
              <b-form-group>
                <b-input
                  size="sm"
                  v-model="tariff.price_km_intercity"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col class="mt-1">
              <label>Цена за 1час:</label>
              <b-form-group>
                <b-input size="sm" v-model="tariff.price_hour" type="number" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="group">
              <label>Порядок:<i style="color: red">*</i></label>
              <b-form-group>
                <b-input size="sm" v-model="tariff.sort" type="number" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label>Порог расстояния для рекламы цена:</label>
                <b-input
                  size="sm"
                  v-model="tariff.price_advertising"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <label
                >Минимальная сумма неустойки:<i style="color: red">*</i></label
              >
              <b-form-group>
                <b-input size="sm" type="number" v-model="tariff.min_penalty" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col style="margin-top: 35px">
              <label>Цена заезда <i style="color: red">*</i></label>
              <b-form-group>
                <b-input
                  size="sm"
                  v-model="tariff.check_in_price"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <label
                >Бесплатное ожидание (в минутах)
                <i style="color: red">*</i></label
              >
              <b-form-group>
                <b-input
                  size="sm"
                  v-model="tariff.free_waiting_of_client_in_minute"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col style="margin-top: 18px">
              <label>Радиус автоназначение <i style="color: red">*</i></label>
              <b-form-group>
                <b-input
                  size="sm"
                  v-model="tariff.radius_auto_assignment"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col style="margin-top: 37px">
              <label>Округление цены <i style="color: red">*</i></label>
              <b-form-group>
                <b-input
                  size="sm"
                  v-model="tariff.price_rounding"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <label>Количество мест:</label>
              <b-form-group>
                <b-input
                  size="sm"
                  v-mask="'#'"
                  v-model="tariff.seats"
                  type="text"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col style="margin-top: 28px">
              <label class="d-flex">
                <b-form-checkbox
                  v-model="tariff.is_active"
                  id="2"
                ></b-form-checkbox>
                <div style="user-select: none; font-size: 16px">
                  Блокирование (вкл или вылк блокирование тарифа)
                </div>
              </label>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6" class="mt-2">
          <b-row>
            <b-col cols="7">
              <b-form-group
                style="
                  border: 1px solid rgb(231, 227, 227);
                  padding: 7px 12px;
                  border-radius: 5px;
                "
              >
                <span><b>Надбавки</b></span>
              </b-form-group>
            </b-col>
            <b-col cols="5">
              <b-col>
                <b-form-group
                  style="
                    border: 1px solid rgb(231, 227, 227);
                    padding: 7px 12px;
                    border-radius: 5px;
                  "
                >
                  <span><b>Стоимость</b></span>
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>

          <template v-if="tariffType">
            <b-row v-for="(item, id) in tariffType.allowances" :key="id">
              <b-col cols="7">
                <b-form-group
                  style="
                    border: 1px solid rgb(231, 227, 227);
                    padding: 7px 12px;
                    border-radius: 5px;
                  "
                >
                  <span>{{ item.name }}<i style="color: red">*</i></span>
                </b-form-group>
              </b-col>
              <b-col cols="5">
                <b-col>
                  <b-form-group>
                    <b-input
                      v-model="item.price"
                      type="number"
                      placeholder="Цена"
                    />
                  </b-form-group>
                </b-col>
              </b-col>
            </b-row>
          </template>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-between">
          <b-button
            class="mt-2 col-md-5"
            variant="secondary"
            @click="closeModal"
          >
            Отмена
          </b-button>
          <b-button
            :disabled="submitButtonDisabled"
            class="mt-2 col-md-5"
            variant="primary"
            @click="createTariff"
          >
            Добавить
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { mask } from "vue-the-mask";
export default {
  directives: { mask },
  components: {
    vSelect,
    ToastificationContent,
  },
  props: ["divisions", "tariffTypes", "tariffsBase", "data_pay_types"],
  data() {
    return {
      tariff: {
        name: undefined,
        division_id: undefined,
        delivery_price: undefined,
        type_tariff_id: undefined,
        min_price: undefined,
        price_hour: undefined,
        price_km_city: undefined,
        price_km_intercity: undefined,
        sort: undefined,
        price_advertising: undefined,
        min_penalty: undefined,
        free_waiting_of_client_in_minute: undefined,
        check_in_price: undefined,
        radius_auto_assignment: null,
        price_rounding: null,
        is_active: false,
        allowances: [],
        seats: null,
        pay_types: [],
      },
      tariffType: null,
      submitButtonDisabled: false,
    };
  },
  methods: {
    createTariff() {
      this.tariff.is_active = this.tariff.is_active == true ? 1 : 0;
      this.tariff.price_rounding = this.tariff.price_rounding == true ? 1 : 0;
      this.tariff.radius_auto_assignment =
        this.tariff.radius_auto_assignment == true ? 1 : 0;
      this.tariff.type_tariff_id = this.tariffType.id;
      let data = JSON.parse(JSON.stringify(this.tariff));
      data.allowances = JSON.stringify(this.tariffType.allowances);
      this.$http
        .post(`base-tariffs`, data)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.closeModal();
          this.$emit("refresh");
          this.clearData();
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
    clearData() {
      (this.tariff = {
        name: undefined,
        division_id: undefined,
        delivery_price: undefined,
        type_tariff_id: undefined,
        min_price: undefined,
        price_hour: undefined,
        price_km_city: undefined,
        price_km_intercity: undefined,
        sort: undefined,
        price_advertising: undefined,
        min_penalty: undefined,
        free_waiting_of_client_in_minute: undefined,
        check_in_price: undefined,
        radius_auto_assignment: null,
        price_rounding: null,
        is_active: false,
        allowances: [],
        seats: null,
        pay_types: [],
      }),
        (this.tariffType = null);
    },
    closeModal() {
      this.$bvModal.hide(this.$route.name + "CreateModal");
      this.clearData();
    },
  },
};
</script>

<style scoped>
.group {
  margin-top: 30px;
}
</style>
