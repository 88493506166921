<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-tariff
        :tariffsBase="tariffsBase"
        @editForm="editForm"
        @copyForm="copyForm"
        @blockUnlock="blockUnlock"
        @activeVersion="activeVersion"
        @rollbackVersion="rollbackVersion"
        @refresh="refresh"
      />
    </div>
    <modal-block-unlock :id="id" @refresh="refresh" />
    <modal-active-version :id="id" @refresh="refresh" />
    <modal-rollback-version :id="id" @refresh="refresh" />
    <modal-tariff
      :tariffsBase="tariffsBase"
      :tariffTypes="tariffTypes"
      :data_pay_types="data_pay_types"
      :divisions="divisions"
      @refresh="refresh"
    />
    <modal-tariff-copy
      :tariffTypes="tariffTypes"
      :divisions="divisions"
      :data_pay_types="data_pay_types"
      @refresh="refresh"
      :id="id"
    />
    <modal-tariff-edit
      :tariffTypes="tariffTypes"
      :divisions="divisions"
      :data_pay_types="data_pay_types"
      @refresh="refresh"
      :id="id"
    />
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
      :name="name"
    />
  </div>
</template>

<script>
import tableTariff from "@/views/component/Table/tableTariff.vue";
import ModalTariff from "@/views/component/Modal/ModalTariff/modalTariff.vue";
import modalTariffCopy from "@/views/component/Modal/ModalTariff/modalTariffCopy.vue";
import ModalTariffEdit from "@/views/component/Modal/ModalTariff/modalTariffEdit.vue";
import ModalBlockUnlock from "@/views/component/Modal/ModalTariff/ModalBlockUnlock.vue";
import ModalActiveVersion from "@/views/component/Modal/ModalTariff/ModalActiveVersion.vue";
import ModalRollbackVersion from "@/views/component/Modal/ModalTariff/ModalRollbackVersion.vue";
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  components: {
    tableTariff,
    ModalTariff,
    modalTariffCopy,
    ModalTariffEdit,
    ModalBlockUnlock,
    ModalActiveVersion,
    ModalRollbackVersion,
  },
  data() {
    return {
      id: null,
      fields: [],
      divisions: [],
      allowances: [],
      tariffTypes: [],
      tariffsBase: [],
      carCategories: [],
      showPreloader: false,
      category_tariffs: [],
      type_tariffs: [],
      data_pay_types: [],
      fields: [
        { key: "id", label: "Id" },
        { key: "division_base_tariff", label: "Подразделение" },
        { key: "calc_type", label: "Тип расчета" },
        { key: "delivery_price", label: "Цена подачи" },
        { key: "min_price", label: "Минимальная цена" },
        { key: "is_active", label: "Статус" },
        { key: "radius_auto_assignment", label: "Автоматическое н/р" },
      ],
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.openFilter();
    this.getTariffData();
    this.getTariffTypeData();
  },
  methods: {
    refresh() {
      this.showPreloader = true;
      this.$http.get(`${this.name}`).then((res) => {
        this.tariffsBase = res.data;
        this.$store.commit("pageData/setdataCount", this.tariffsBase.length);
        this.showPreloader = false;
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    getTariffTypeData() {
      this.$http.get("tariff/types/data").then((res) => {
        this.carCategories = res.data.car_categories;
        this.divisions = res.data.divisions;
        this.allowances = res.data.allowances;
        this.category_tariffs = res.data.category_tariffs;
      });
    },
    getTariffData() {
      this.$http.get(`base-tariffs/data`).then((res) => {
        this.data_pay_types = res.data.pay_types;
        this.tariffTypes = res.data.type_tariffs;
        this.divisions = res.data.divisions;
      });
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.name}`, { params: param }).then((res) => {
          this.tariffsBase = res.data;
          this.$store.commit("pageData/setdataCount", this.tariffsBase.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    sendToParent(tableData) {
      this.tariffsBase = tableData;
      this.$store.commit("pageData/setdataCount", this.tariffsBase.length);
    },
    editForm(id) {
      this.id = id;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show("tariffEdit");
    },
    copyForm(id) {
      this.id = id;
      this.openCopyModal();
    },
    openCopyModal() {
      this.$bvModal.show("copyTariff");
    },
    blockUnlock(id) {
      this.id = id;
      this.$bvModal.show("blockUnlock");
    },
    activeVersion(id) {
      this.id = id;
      this.$bvModal.show("activeVersionOpen");
    },
    rollbackVersion(id) {
      this.id = id;
      this.$bvModal.show("rollbackVersionOpen");
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        this.showPreloader = true;
        let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
        this.$http.get(`${this.name}`, { params: param }).then((res) => {
          this.tariffsBase = res.data;
          this.showPreloader = false;
          this.$store.commit("pageData/setdataCount", this.tariffsBase.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
