<template>
    <div>
        <b-modal
        id="rollbackVersionOpen"
        title="Отменить изменения"
        size="sm"
        hide-footer 
        no-enforce-focus 
        :no-close-on-backdrop="true">
            <b-row>
                <b-col>
                    <b-row>
                        <b-col>
                            <label>Причина отмены изменения:</label>
                            <b-form-textarea
                            id="textrea"
                            v-model="comment"
                            placeholder="Причина отмены изменения..."
                            rows="3"
                            max-rows="6"
                            ></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="rollbackTariff">
                        Ок
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
    export default {
        props: ['id'],
        components: {
            ToastificationContent
        },
        data() {
            return {
                comment: null,
                submitButtonDisabled: false
            }
        },
        methods: {
            rollbackTariff() {
                this.submitButtonDisabled = true
                this.$http
                    .post(`base-tariffs/${ this.id }/rollback-version?comment=${ this.comment }`)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Успех!',
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: res.data.message,
                            },
                        })
                        this.btnCancel()
                        this.$emit('refresh')
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.message,
                            },
                        })
                    })
                    .finally(() => {
                        this.submitButtonDisabled = false
                    })
            },
            btnCancel() {
                this.$bvModal.hide('rollbackVersionOpen')
                this.comment = null
            }
        }
    }
</script>
